
import { GET_REQUEST, DELETE_REQUEST, POST_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import { convertIdToColor,getSelectDataList } from "@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils";
import { convertDateFormat, dateDiff } from "@lemontree-ai/lemontree-admin-common-front/utils/dateUtils";
import { removeComma, addComma, endWord } from '@lemontree-ai/lemontree-admin-common-front/utils/stringUtils'

import cancelModel from '@/views/service/membership/payments/model/CancelModel';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';

export default class CsUserByUserDataListViewModel {
  constructor() {
    this.detailData = {};
    this.boardData = {
      CARD_APPLICATIONS: {
        searched: false,
        hasData: true,
        responseDate:'',
        boardData:{
          title:'카드 신청서 내역',
          drawDataList: [
            {
              title:'ID',
              width:'100px',
              value:'id',
              isAlignLeft: true,
            },
            {
              title:'신청자(부모)<br/>PAY ID',
              width:'100px',
              value:'cardApplicantPayId',
              isAlignLeft: true,
            },
            {
              title:'사용자(자녀)<br/>PAY ID',
              width:'100px',
              value:'cardOwnerPayId',
              isAlignLeft: true,
            },
            
            {
              title:'',
              width:'38px',
              value:'cardDesignType',
              img: {
                width: '25px',
                height: '38px',
              },
              valueCustom:[
                { 
                  type: 'text',
                  value: '/assets/images/cs_user_byuserdata/card_design_',
                },
                {
                  type: 'value',
                  value:'cardDesignType',
                },
                { 
                  type: 'text',
                  value: '.png',
                },
              ],
              isAlignLeft: true,
            },
            {
              title:'디자인',
              width:'88px',
              value:'cardDesignType',
              filter: { name:'convertIdToText', value:'cs_card_design_type' },
              isAlignLeft: true,
            },
            {
              title:'카드 상태',
              width:'118px',
              value:'status',
              filter: { name:'convertIdToText', value:'card_apply_status' },
              badge: {
                badgeColorFilter:{
                  name:'convertIdToColor',
                  dataListName:'card_apply_status'
                },
                badgeFill: false
              }
            },
            {
              title:'발급 신청<br/>구분 상태',
              width:'100px',
              value:'issueDivisionStatus',
            },
            {
              title:'재발급 사유',
              width:'68px',
              value:'cardReissueTransaction.reason',
              isBreakWord: true
            },
            {
              title:'유/무료<br/>구분',
              width:'60px',
              value:'checkFeeType',
            },
            {
              title:'멤버십시점<br/>신청 여부',
              width:'74px',
              value:'inMembership',
              filter: { name:'convertIdToText', value:'common_boolean' },
            },
            {
              title:'신청일시',
              width:'154px',
              value:'applicationAt',
              filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
              isAlignLeft: true,
            },
            // {
            //   title:'신청서 접수일시',
            //   width:'154px',
            //   value:'registeredAt',
            //   filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
            //   isAlignLeft: true,
            // },
            {
              title:'취소 일시',
              width:'154px',
              value:'canceledAt',
              filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
              isAlignLeft: true,
            },
            {
              title:'신청서 전송일시',
              width:'160px',
              value:'sendAt',
              filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
              isAlignLeft: true,
            },
          ],
          option:{
            isTotal: true,
            isSize: false,
            rowIdValue: 'id'
          },
        },
        dataList:[],
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData: {
          totalCount:0,
          totalPage:0
        }
      },
      DELIVERY_STATUS: {
        searched: false,
        hasData: true,
        responseDate:'',
        dataList:[],
      },
      CARD_HISTORY: {
        searched: false,
        hasData: true,
        responseDate:'',
        boardData:{
          title:'카드 사용 내역',
          drawDataList: [
            {
              title:'카드번호',
              width:'156px',
              value:'cardNumber',
              class:{
                name: 'break_word'
              },
              isAlignLeft: true,
            },
            {
              title:'승인번호',
              width:'100px',
              value:'approveNumber',
              isAlignLeft: true,
            },
            {
              title:'업종코드',
              width:'80px',
              value:'affiliateCode',
              isAlignLeft: true,
            },
            {
              title:'가맹점 번호',
              width:'140px',
              value:'affiliateNumber',
              isAlignLeft: true,
            },
            {
              title:'가맹점명',
              width:'130px',
              value:'affiliateName',
              isAlignLeft: true,
              isEllip: true
            },
            {
              title:'금액',
              width:'100px',
              value:'transactionAmount',
              filter: { name:'convertNumberToComma', value:false, value2:'0원', value3:'원' },
              isAlignRight: true,
            },
            {
              title:'거래일시',
              width:'154px',
              value:'transactionAt',
              filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
              isAlignLeft: true,
            },
            {
              title:'거래상태',
              width:'70px',
              value:'transactionType',
              filter:{
                name:'convertIdToText',
                value:'cs_card_transaction_type'
              },
              badge: {
                badgeColorFilter:{
                  name:'convertIdToColor',
                  dataListName:'cs_card_transaction_type'
                },
                badgeFill: false
              },
              isAlignLeft: true,
            },
            {
              title:'설명',
              width:'',
              value:'description',
              isAlignLeft: true,
            },
          ],
          option:{
            isTotal: true,
            isSize: true,
            rowIdValue: 'approveNumber'
          },
        },
        dataList:[],
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData: {
          totalCount:0,
          totalPage:0
        }
      },
      TRANS_HISTORY: {
        searched: false,
        hasData: true,
        responseDate:'',
        boardData:{
          title:'입출금 내역',
          drawDataList: [
            {
              title:'거래타입',
              width:'90px',
              value:'transactionType',
            },
            {
              title:'ID',
              width:'110px',
              value:'id',
              isAlignLeft: true,
            },
            {
              title:'거래명',
              width:'100px',
              value:'transactionName',
              isAlignLeft: true,
              isEllip: true
            },
            {
              title:'입출금자명',
              width:'100px',
              value:'',
              isAlignLeft: true,
              valueCustom:[
                { 
                  type: 'value',
                  value: 'withdrawOwnerName',
                  if:{
                    value:'transactionType',
                    condition:'WITHDRAW'
                  }
                },
                { 
                  type: 'value',
                  value: 'depositOwnerName',
                  if:{
                    value:'transactionType',
                    condition:'DEPOSIT'
                  }
                },
              ],
              isEllip: true
            },
            {
              title:'입출금은행',
              width:'100px',
              value:'',
              isAlignLeft: true,
              isEllip: true,
              valueCustom:[
                { 
                  type: 'value',
                  value: 'withdrawBankName',
                  if:{
                    value:'transactionType',
                    condition:'WITHDRAW'
                  }
                },
                { 
                  type: 'value',
                  value: 'depositBankName',
                  if:{
                    value:'transactionType',
                    condition:'DEPOSIT'
                  }
                },
              ]
            },
            {
              title:'입출금계좌번호',
              width:'130px',
              value:'',
              isAlignLeft: true,
              valueCustom:[
                { 
                  type: 'value',
                  value: 'withdrawAccountNumber',
                  if:{
                    value:'transactionType',
                    condition:'WITHDRAW'
                  }
                },
                { 
                  type: 'value',
                  value: 'depositAccountNumber',
                  if:{
                    value:'transactionType',
                    condition:'DEPOSIT'
                  }
                },
              ]
            },
            {
              title:'금액',
              width:'100px',
              value:'requestAmount',
              filter: { name:'convertNumberToComma', value:false, value2:'0원', value3:'원' },
              isAlignRight: true,
            },
            {
              title:'거래생성일시',
              width:'154px',
              value:'createdAt',
              filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
              isAlignLeft: true,
            },
            {
              title:'거래상태',
              width:'',
              value:'status',
              class: {
                classNameConvert:(value,rowData)=>{
                  return `fs_small tc_${convertIdToColor(rowData.status,'cs_transaction_status')}`
                },
              },
              valueCustom:[
                { 
                  type: 'value',
                  value: 'responseMessage',
                  ifNot:{
                    value:'status',
                    condition:'성공'
                  }
                },
              ],
              isAlignLeft: true,
            },
          ],
          option:{
            isTotal: true,
            isSize: true,
          },
        },
        dataList:[],
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData: {
          totalCount:0,
          totalPage:0
        }
      },
      VIRTUAL_ACCOUNT_TRANSACTIONS: {
        searched: false,
        hasData: true,
        responseDate:'',
        boardData:{
          title: '가상계좌 거래내역',
          drawDataList: [
            {
              title:'보내는이',
              width:'90px',
              value:'sender_name',
              emptyValueText:'-'
            },
            {
              title:'거래명',
              width:'',
              value:'transaction_name',
              isAlignLeft: true,
              isEllip: true,
              emptyValueText:'-'
            },
            {
              title:'거래금액',
              width:'160px',
              value:'transaction_amount',
              filter: { name:'convertNumberToComma', value:false, value2:'0원', value3:'원' },
              isAlignLeft: true,
            },
            {
              title:'거래일시',
              width:'154px',
              value:'transaction_at',
              filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
              isAlignLeft: true,
            },
            {
              title:'거래상태',
              width:'100px',
              value:'status',
              filter:{
                name:'convertIdToText',
                value:'cs_response_type'
              },
              class: {
                classNameConvert:(value,rowData)=>{
                  return `fs_small tc_${convertIdToColor(rowData.status,'cs_response_type')}`
                },
              },
            },
          ],
          option:{
            isTotal: true,
            isSize: true,
            rowIdValue: 'transaction_at'
          },
        },
        dataList:[],
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData: {
          totalCount:0,
          totalPage:0
        }
      },
      BARCODE_TRANSACTION_HISTORY: {
        searched: false,
        hasData: true,
        responseDate:'',
        boardData:{
          title:'바코드 결제내역',
          drawDataList: [
            {
              title:'브랜드명',
              width:'100px',
              value:'brandName',
              isAlignLeft: true,
              isEllip: true
            },
            {
              title:'트랜잭션 타입',
              width:'90px',
              value:'transactionType',
              filter:{
                name:'convertIdToText',
                value:'cs_barcode_transaction_type'
              },
            },
            {
              title:'상태',
              width:'50px',
              value:'status',
              filter:{
                name:'convertIdToText',
                value:'cs_response_type'
              },
            },
            {
              title:'승인 번호',
              width:'200px',
              value:'approveNumber',
              isAlignLeft: true,
            },
            {
              title:'승인 시간',
              width:'154px',
              value:'transactionAt',
              filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
              isAlignLeft: true,
            },
            {
              title:'트랜잭션 이름',
              width:'100px',
              value:'transactionName',
              isAlignLeft: true,
              isEllip: true,
            },
            {
              title:'승인 금액',
              width:'100px',
              value:'transactionAmount',
              filter: { name:'convertNumberToComma', value:false, value2:'0원', value3:'원' },
              isAlignRight: true,
            },
            {
              title:'페이백 금액',
              width:'100px',
              value:'paybackAmount',
              filter: { name:'convertNumberToComma', value:false, value2:'0원', value3:'원' },
              isAlignRight: true,
            },
            {
              title:'바코드 번호',
              width:'158px',
              value:'barcodeNumber',
            },
          ],
          option:{
            isTotal: true,
            isSize: true,
            rowIdValue: 'uid'
          },
        },
        dataList:[],
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData: {
          totalCount:0,
          totalPage:0
        }
      },
      WALLET_HISTORY: {
        searched: false,
        hasData: true,
        responseDate:'',
        boardData:{
          title:'지갑 내역',
          drawDataList: [
            {
              title:'ID',
              width:'110px',
              value:'id',
              isAlignLeft: true,
            },
            {
              title:'거래라벨',
              width:'90px',
              value:'transactionLabel',
            },
            {
              title:'거래타입',
              width:'90px',
              value:'transactionType',
            },
            {
              title:'거래카테고리',
              width:'134px',
              value:'transactionCategory',
              isAlignLeft: true,
              isEllip2: true,
            },
            {
              title:'결제카테고리',
              width:'100px',
              value:'paymentCategory',
              isAlignLeft: true,
              isEllip: true,
              emptyValueText:'-'
            },
            {
              title:'거래명',
              width:'',
              value:'transactionName',
              isAlignLeft: true,
              isEllip2: true,
              class:{
                name: 'fs_small'
              },
            },
            {
              title:'보낸<br>PAY ID',
              width:'110px',
              value:'sendUserId',
              isAlignLeft: true,
              isEllip: true
            },
            {
              title:'받는<br>PAY ID',
              width:'110px',
              value:'receiveUserId',
              isAlignLeft: true,
              isEllip: true
            },
            {
              title:'금액',
              width:'90px',
              value:'transactionAmount',
              filter: { name:'convertNumberToComma', value:false, value2:'0원', value3:'원' },
              isAlignRight: true,
            },
            {
              title:'거래후금액',
              width:'90px',
              value:'afterAmount',
              filter: { name:'convertNumberToComma', value:false, value2:'0원', value3:'원' },
              isAlignRight: true,
            },
            {
              title:'거래생성일시',
              width:'100px',
              value:'createdAt',
              isAlignLeft: true,
              class:{
                name: 'fs_small'
              },
              valueCustom:[
                { 
                  type: 'value',
                  value: 'createdAt',
                  filter: { name:'convertDateFormat', value:'YYYY-MM-DD' },
                },
                { 
                  type: 'text',
                  value: '<br>',
                },
                { 
                  type: 'value',
                  value: 'createdAt',
                  filter: { name:'convertDateFormat', value:'HH:mm:ss' },
                },
              ],
            },
            {
              title:'거래상태',
              width:'90px',
              value:'status',
              isAlignLeft: true,
            },
          ],
          option:{
            isTotal: true,
            isSize: true,
          },
        },
        dataList:[],
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData: {
          totalCount:0,
          totalPage:0
        }
      },
      MONEY_HISTORY: {
        searched: false,
        hasData: true,
        responseDate:'',
        boardData:{
          title:'지갑 내역',
          drawDataList: [
            {
              title:'ID',
              width:'110px',
              value:'id',
              isAlignLeft: true,
            },
            {
              title:'거래라벨',
              width:'90px',
              value:'transactionLabel',
            },
            {
              title:'거래타입',
              width:'90px',
              value:'transactionType',
            },
            {
              title:'거래카테고리',
              width:'134px',
              value:'transactionCategory',
              isAlignLeft: true,
              isEllip2: true,
            },
            {
              title:'결제카테고리',
              width:'100px',
              value:'paymentCategory',
              isAlignLeft: true,
              isEllip: true,
              emptyValueText:'-'
            },
            {
              title:'거래명',
              width:'',
              value:'transactionName',
              isAlignLeft: true,
              isEllip2: true,
              class:{
                name: 'fs_small'
              },
            },
            {
              title:'보낸<br>PAY ID',
              width:'110px',
              value:'sendUserId',
              isAlignLeft: true,
              isEllip: true
            },
            {
              title:'받는<br>PAY ID',
              width:'110px',
              value:'receiveUserId',
              isAlignLeft: true,
              isEllip: true
            },
            {
              title:'금액',
              width:'90px',
              value:'transactionAmount',
              filter: { name:'convertNumberToComma', value:false, value2:'0원', value3:'원' },
              isAlignRight: true,
            },
            {
              title:'거래후금액',
              width:'90px',
              value:'afterAmount',
              filter: { name:'convertNumberToComma', value:false, value2:'0원', value3:'원' },
              isAlignRight: true,
            },
            {
              title:'거래생성일시',
              width:'100px',
              value:'createdAt',
              isAlignLeft: true,
              class:{
                name: 'fs_small'
              },
              valueCustom:[
                { 
                  type: 'value',
                  value: 'createdAt',
                  filter: { name:'convertDateFormat', value:'YYYY-MM-DD' },
                },
                { 
                  type: 'text',
                  value: '<br>',
                },
                { 
                  type: 'value',
                  value: 'createdAt',
                  filter: { name:'convertDateFormat', value:'HH:mm:ss' },
                },
              ],
            },
            {
              title:'거래상태',
              width:'90px',
              value:'status',
              isAlignLeft: true,
            },
          ],
          option:{
            isTotal: true,
            isSize: true,
          },
        },
        dataList:[],
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData: {
          totalCount:0,
          totalPage:0
        }
      },
      POINT_HISTORY: {
        searched: false,
        hasData: true,
        responseDate:'',
        boardData:{
          title:'지갑 내역',
          drawDataList: [
            {
              title:'ID',
              width:'110px',
              value:'id',
              isAlignLeft: true,
            },
            {
              title:'거래라벨',
              width:'70px',
              value:'transactionLabel',
            },
            {
              title:'거래타입',
              width:'88px',
              value:'transactionType',
            },
            {
              title:'거래카테고리',
              width:'120px',
              value:'transactionCategory',
              isAlignLeft: true,
              isEllip2: true,
            },
            {
              title:'결제카테고리',
              width:'90px',
              value:'paymentCategory',
              isAlignLeft: true,
              isEllip: true,
              emptyValueText:'-'
            },
            {
              title:'거래명',
              width:'',
              value:'transactionName',
              isAlignLeft: true,
              isEllip2: true,
              class:{
                name: 'fs_small'
              },
            },
            {
              title:'보낸<br>PAY ID',
              width:'110px',
              value:'sendUserId',
              isAlignLeft: true,
              isEllip: true
            },
            {
              title:'받는<br>PAY ID',
              width:'110px',
              value:'receiveUserId',
              isAlignLeft: true,
              isEllip: true
            },
            {
              title:'금액',
              width:'90px',
              value:'transactionAmount',
              filter: { name:'convertNumberToComma', value:false, value2:'0원', value3:'원' },
              isAlignRight: true,
            },
            {
              title:'거래후금액',
              width:'90px',
              value:'afterAmount',
              filter: { name:'convertNumberToComma', value:false, value2:'0원', value3:'원' },
              isAlignRight: true,
            },
            {
              title:'거래생성일시',
              width:'100px',
              value:'createdAt',
              isAlignLeft: true,
              class:{
                name: 'fs_small'
              },
              valueCustom:[
                { 
                  type: 'value',
                  value: 'createdAt',
                  filter: { name:'convertDateFormat', value:'YYYY-MM-DD' },
                },
                { 
                  type: 'text',
                  value: '<br>',
                },
                { 
                  type: 'value',
                  value: 'createdAt',
                  filter: { name:'convertDateFormat', value:'HH:mm:ss' },
                },
              ],
            },
            {
              title:'거래상태',
              width:'90px',
              value:'status',
              isAlignLeft: true,
            },
          ],
          option:{
            isTotal: true,
            isSize: true,
          },
        },
        dataList:[],
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData: {
          totalCount:0,
          totalPage:0
        }
      },
      TRANSACTION_LIMIT: {
        searched: false,
        hasData: true,
        responseDate:'',
        data:[],
      },
      TRANSACTION_LIMIT_POSSIBLE: {
        searched: false,
        hasData: true,
        responseDate:'',
        data:[],
      },
      DEDUCTION_HISTORY: {
        searched: false,
        hasData: true,
        responseDate:'',
        boardData:{
          title:'소득공제 내역',
          drawDataList: [
            {
              title:'신청서ID',
              width:'110px',
              value:'id',
              // isAlignLeft: true,
            },
            {
              title:'신청자이름',
              width:'80px',
              value:'parents_name',
            },
            {
              title:'자녀이름',
              width:'70px',
              value:'child_name',
            },
            {
              title:'상태',
              // width:'50px',
              value:'status',
            },
            {
              title:'신청일시',
              width:'110px',
              value:'created_at',
              filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
            },
            {
              title:'주민번호 뒷자리 해시값',
              width:'200px',
              value:'etc_back_hash',
              class:{
                name: 'break_word'
              },
            },
            {
              title:'카드ID',
              width:'60px',
              value:'card_id',
            },
            {
              title:'카드번호',
              width:'70px',
              value:'card_number',
              class:{
                name: 'break_word'
              },
            },
            {
              title:'카드상태',
              // width:'80px',
              value:'card_status',
            },
            {
              title:'카드생성일시',
              width:'110px',
              value:'card_created_at',
              filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
            },
          ],
          option:{
            isTotal: false,
            isSize: false,
          },
        },
        dataList:[],
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData: {
          totalCount:0,
          totalPage:0
        }
      },
      MEMBERSHIP_PAYMENT_HISTORY: {
        searched: false,
        hasData: true,
        responseDate:'',
        boardData:{
          title:'멤버십 구매내역',
          drawDataList: [
            {
              title:'구매 퍼핀<br>회원ID',
              width:'80px',
              value:'user_id',
              isAlignLeft: true,
            },
            // {
            //   title:'역할',
            //   width:'44px',
            //   value:'user_role',
            //   filter:{
            //     name:'convertIdToText',
            //     value:'user_role'
            //   },
            //   class: {
            //     classColorFilter:{
            //       name:'convertIdToColor',
            //       dataListName:'user_role',
            //       prev:'fw_bold tc'
            //     }
            //   },
            // },
            {
              title:'구매회원<br>이름',
              width:'62px',
              value:'name',
              isAlignLeft: true,
              isEllip: true
            },
            {
              title:'구매회원<br>계정상태',
              width:'62px',
              value:'user_status',
              filter:{
                name:'convertIdToText',
                value:'user_status_name'
              },
              class: { classColorFilter:{ name:'convertIdToColor', dataListName:'user_status_name', prev:'tc' } }
            },
            {
              title:'멤버십 기한',
              width:'186px',
              value:'',
              dateRangeValue:{
                startValue:'membership_start_at',
                endValue:'membership_end_at',
                betweenText:' ~ ',
                isEndPastDateToRed:true
              },
              isEllip: true,
              isAlignLeft: true
            },
            {
              title:'멤버십<br>상태',
              width:'52px',
              value:'user_membership_status',
              filter:{
                name:'convertIdToText',
                value:'user_status_name'
              },
              class: { classColorFilter:{ name:'convertIdToColor', dataListName:'user_status_name', prev:'tc' } }
            },
            {
              title:'멤버십 ID',
              width:'66px',
              value:'user_membership_id',
              isAlignLeft: true,
            },
            {
              title:'멤버십 UID',
              width:'120px',
              value:'user_membership_uid',
              isAlignLeft: true,
              class:{
                name:'txt_break_all'
              }
            },
            {
              title:'멤버십종류',
              width:'76px',
              value:'membership',
              filter:{
                name:'convertIdToText',
                value:'membership_type'
              },
            },
            // {
            //   title:'membership_id',
            //   width:'76px',
            //   value:'membership_id',
            //   // isAlignLeft: true,
            // },
            {
              title:'기간옵션',
              width:'76px',
              value:'membership_title',
              // isAlignLeft: true,
            },
            // {
            //   title:'자동결제옵션',
            //   width:'88px',
            //   value:'membership_subscription_type',
            //   // isAlignLeft: true,
            // },
            {
              title:'결제타입',
              width:'74px',
              value:'purchase_method_type',
              filter:{
                name:'convertIdToText',
                value:'membership_purchase_method_type'
              },
              badge: {
                badgeColorFilter:{
                  name:'convertIdToColor',
                  dataListName:'membership_purchase_method_type'
                },
                badgeFill: true
              }
            },
            {
              title:'주문번호',
              width:'170px',
              value:'order_id',
              isAlignLeft: true,
              class:{
                name:'txt_break_all'
              }
            },
            {
              title:'결제상태',
              width:'64px',
              value:'payment_status',
              filter:{
                name:'convertIdToText',
                value:'membership_payment_status'
              },
              class: { classColorFilter:{ name:'convertIdToColor', dataListName:'membership_payment_status', prev:'tc' } }
            },
            {
              title:'결제금액',
              width:'72px',
              value:'amount',
              filter: { name:'convertNumberToComma', value:'',value2:'0원',value3:'원' },
              isMobileTitle:true,
              isAlignRight: true,
            },
            {
              title:'결제일시',
              width:'156px',
              value:'purchased_at',
              filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
              isMobileTitle:true,
              isAlignLeft: true,
            },
            // {
            //   title:'구매금액',
            //   width:'78px',
            //   value:'',
            //   isAlignRight: true,
            //   valueCustom:[
            //     {
            //       type: 'value',
            //       value:'amount',
            //       filter:{
            //         name:'convertNumberToComma',
            //       },
            //       emptyValueText: '0',
            //     },
            //     { 
            //       type: 'text',
            //       value: '원'
            //     },
            //   ],
            // },
            // {
            //   title:'환불금액',
            //   width:'80px',
            //   value:'',
            //   isAlignRight: true,
            //   valueCustom:[
            //     {
            //       type: 'value',
            //       value:'cancel_amount',
            //       filter:{
            //         name:'convertNumberToComma',
            //       },
            //       emptyValueText: '0',
            //     },
            //     { 
            //       type: 'text',
            //       value: '원'
            //     },
            //   ],
            // },
            {
              title:'구매취소',
              width:'92px',
              value:'user_membership_status',
              buttonCondition: [
                { condition:'REGISTERED', text:'구매취소', size:'small', style:'fourthly_border', disabled:false, onClickEvent: (data) => this.onClickMembershipCancel(data) },
              ],
            },
          ],
          option:{
            isTotal: true,
            isSize: false,
            rowIdValue: 'user_membership_uid'
          },
        },
        dataList:[],
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData: {
          totalCount:0,
          totalPage:0
        },
        isMembershipPaymentCancelView:false,
        membershipDetailData:{},
        cancelModel: new cancelModel(),
        checkboxOnlyOne: false,
        isShowRowCheck: false,
        checkboxData:{
          title:'',
          width:'42px',
          value:'',
          isCheckbox:true
        },
        selectedDataList: [],
      },
    };

    this.transHistroyBoardData = {
      title:'입출금 내역',
      drawDataList: [
        {
          title:'거래타입',
          width:'80px',
          value:'transactionType',
          filter:{
            name:'convertIdToText',
            value:'cs_transaction_type'
          },
          badge: {
            badgeColorFilter:{
              name:'convertIdToColor',
              dataListName:'cs_transaction_type'
            },
            badgeFill: false
          },
        },
        {
          title:'ID',
          width:'110px',
          value:'id',
          isAlignLeft: true,
        },
        {
          title:'거래명',
          width:'100px',
          value:'transactionName',
          isAlignLeft: true,
          isEllip: true
        },
        {
          title:'입출금자명',
          width:'100px',
          value:'',
          isAlignLeft: true,
          valueCustom:[
            { 
              type: 'value',
              value: 'depositOwnerName',
              if:{
                value:'transactionType',
                condition:'WITHDRAW'
              }
            },
            { 
              type: 'value',
              value: 'withdrawOwnerName',
              if:{
                value:'transactionType',
                condition:'DEPOSIT'
              }
            },
          ],
          isEllip: true
        },
        {
          title:'입출금계좌',
          width:'130px',
          value:'',
          isAlignLeft: true,
          valueCustom:[
            { 
              type: 'value',
              value: 'depositAccountNumber',
              if:{
                value:'transactionType',
                condition:'WITHDRAW'
              }
            },
            { 
              type: 'value',
              value: 'withdrawAccountNumber',
              if:{
                value:'transactionType',
                condition:'DEPOSIT'
              }
            },
          ]
        },
        {
          title:'금액',
          width:'100px',
          value:'requestAmount',
          filter: { name:'convertNumberToComma', value:false, value2:'0원', value3:'원' },
          isAlignRight: true,
        },
        {
          title:'거래생성일시',
          width:'154px',
          value:'createdAt',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
          isAlignLeft: true,
        },
        {
          title:'거래상태',
          width:'',
          value:'status',
          class: {
            classNameConvert:(value,rowData)=>{
              return `fs_small tc_${convertIdToColor(rowData.status,'cs_transaction_status')}`
            },
          },
          valueCustom:[
            { 
              type: 'value',
              value: 'responseMessage',
              ifNot:{
                value:'status',
                condition:'성공'
              }
            },
          ],
          isAlignLeft: true,
        },
      ],
      option:{
        isTotal: true,
        isSize: true,
        // rowIdValue: 'payUserId'
      },
    };
    this.virtualAccountsBoardData = {
      boardData:{
        title:'가상계좌 발급내역',
        drawDataList: [
          {
            title:'ID',
            width:'80px',
            value:'id',
            isAlignLeft: true,
          },
          {
            title:'가상계좌<br/>소스ID',
            width:'70px',
            value:'virtualAccountSourceId',
            isAlignLeft: true,
          },
          {
            title:'은행사<br/>ID',
            width:'54px',
            value:'bankCompanyId',
            isAlignLeft: true,
          },
          {
            title:'계좌종류',
            width:'100px',
            value:'accountType',
            isAlignLeft: true,
            isEllip: true
          },
          {
            title:'계좌명',
            width:'100px',
            value:'accountName',
            isAlignLeft: true,
            isEllip: true
          },
          {
            title:'계좌번호',
            width:'',
            value:'accountNumber',
            isAlignLeft: true,
          },
          {
            title:'생성일시',
            width:'154px',
            value:'createdAt',
            filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
            isAlignLeft: true,
          },
          {
            title:'등록일시',
            width:'154px',
            value:'registeredAt',
            filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
            isAlignLeft: true,
          },
          {
            title:'계좌상태',
            width:'100px',
            value:'status',
            isAlignLeft: true,
            filter: { name:'convertIdToText', value:'cs_virtual_account_status' },
            badge: {
              badgeColorFilter:{
                name:'convertIdToColor',
                dataListName:'cs_virtual_account_status'
              },
              badgeFill: false
            }
          },
        ],
        option:{
          isTotal: false,
          isSize: false,
          // rowIdValue: 'payUserId'
        },
      },
      searchParams: {
        page:0,
        pageSize:10,
        direction: 'DESC'
      },
      paginationData: {
        totalCount:0,
        totalPage:0
      },
    };
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
    this.onSearchList;
    this.isCardSuspendReason = ''; // 카드 정지 사유
    this.isCardUpdateFreeCancel = false; // 카드 무료 재발급
    this.cardUpdateFreeCancelReason = ''; // 카드 무료 재발급 사유
    this.cardUpdateFreeCancelEtcReason = ''; // 카드 무료 재발급 기타 사유
    this.isCardDesignChange = false; // 카드 디자인 변경 ui 노출
    this.cardDesignDataList = [];
    this.cardDesignChangedType = ''; // 변경할 카드 디자인
    this.refund_to_leader_amount = '0'; // 리더에게 반환할 금액
    this.cardTransactionCertificate = {
      start_date: '',
      end_date: ''
    }; // 카드 사용 내역 증명서 발급 기간

    this.blockData = {
      // 사용 차단
      NOT_ALLOW_USE_APP:{
        data:{},
        status:'',
        reason:'',
      },
      // 가입 차단
      USER_JOIN_BLOCK:{
        data:{},
        status:'',
        reason:'',
      },
    }
  }
  // 카드 디자인 변경
  onClickCardDesignChange(){
    if(!this.isCardDesignChange){
      const cardDesignType = getSelectDataList('cs_card_design_type');
      this.cardDesignDataList = cardDesignType.map(item => {
        const isCurrentItem = item.id === this.detailData.cardApplication.cardDesignType;
        item.cardDesignImg = `/assets/images/cs_user_byuserdata/card_design_${item.id}.png`;
        item.isDisabled = isCurrentItem;
        return item
      })
      if(!this.cardDesignChangedType){
        this.cardDesignChangedType = cardDesignType.find(item => item.id != this.detailData.cardApplication.cardDesignType).id;
      }
      this.isCardDesignChange = true;
    }else{
      this.onClickCardDesignChangeClose();
    }
  }
  // 카드 디자인 변경 - 닫기
  onClickCardDesignChangeClose(){
    this.isCardDesignChange = false;
    this.cardDesignDataList = [];
  }
  // 카드 디자인 변경 - 완료
  onClickCardDesignChangeComplete(){
    this.postCardChangeDesign();
  }
  // 데이터 세팅
  setDetailData(detailData){
    this.detailData = detailData;
    if(detailData.card){
      this.boardData.CARD_HISTORY.paginationData = {
        totalCount: detailData.card.cardHistories.length,
        totalPage:0
      };
    }
  }

  // 복사
  onClickCopy(linkData,targetName){
    if(targetName){
      targetName = endWord(targetName,'SUBJECT')
    }
    window.navigator.clipboard.writeText(linkData).then(() => {
      store.dispatch('commonToast/fetchToastStart', `${targetName} 복사되었습니다`);
    });
  }
  onClickCopyBasicId(){
    const basicIdData = {
      'pay ID': this.detailData.payUserId,
      '퍼핀 회원 ID': this.detailData.firfinUserId,
      '퍼핀 UID': this.detailData.firfinUserUid,
    }
    window.navigator.clipboard.writeText(this.objcetToString(basicIdData)).then(() => {
      store.dispatch('commonToast/fetchToastStart', `회원의 id 정보들이 복사되었습니다`);
    });
  }
  onClickCopyBasicAll(){
    const basicAllData = {
      'pay ID': this.detailData.payUserId,
      '퍼핀 회원 ID': this.detailData.firfinUserId,
      '퍼핀 UID': this.detailData.firfinUserUid,
      '가족 ID': this.detailData.familyGroupId,
      'pay 계정상태': this.detailData.payUserStatus,
      '퍼핀 회원 계정상태': this.detailData.firfinUserStatus,
      '멤버십 상태': this.detailData.memberships[0].status,
      '가족 상태': this.detailData.familyStatus,
      '역할': this.detailData.familyRole+' '+this.detailData.familyPermission,
      '회원이름': this.detailData.name,
      '생년월일': this.detailData.dateOfBirth,
      '휴대전화': this.detailData.phone,
      '지갑 퍼핀머니 잔액': this.detailData.money,
      '지갑 포인트 잔액': this.detailData.point,
      '저금통 금액': this.detailData.savings.amount,
    }

    window.navigator.clipboard.writeText(this.objcetToString(basicAllData)).then(() => {
      store.dispatch('commonToast/fetchToastStart', `회원의 기본정보가 복사되었습니다`);
    });
  }
  objcetToString(obj){
    const keys = Object.keys(obj);
    let result = '';
    keys.forEach((item, index) => {
      const isLast = index+1 === keys.length;
      const value = obj[item] ? obj[item] : '-'
      let resultItem = `${item}: ${value}${isLast ? '' : ' / '}`;
      result += resultItem;
    })
    return result;
  }
  // 저금통 강제 해지
  onClickDiscardSavings(){
    const requestCallBack = () => {
      this.deleteDiscardSavings();
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `저금통을 정말 강제로 해지하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '저금통 해지하기',
      confirmCallBack: requestCallBack,
    });}
  // 리더에게 머니 반환
  onClickRefundToLeader(){
    const nullInputAmount = this.refund_to_leader_amount == '0' || !this.refund_to_leader_amount;
    const isAllAmount = nullInputAmount ||  this.detailData.money === removeComma(this.refund_to_leader_amount);
    const refundMoney = nullInputAmount ? this.detailData.money : this.refund_to_leader_amount;
    const requestCallBack = () => {
      const refundMoneyToPost = removeComma(this.refund_to_leader_amount);
      this.postRefundToLeader(refundMoneyToPost);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `리더에게 ${this.detailData.name}의 머니 <br>${isAllAmount ? '전액인 ':''}${addComma(refundMoney)}원을 반환하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '반환하기',
      confirmCallBack: requestCallBack,
    });}
  // 카드 취소
  onClickCardCancel(){
    const requestCallBack = () => {
      this.getCardCancel();
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `카드신청을 정말 취소하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '카드 취소하기',
      confirmCallBack: requestCallBack,
    });
  }
  // 카드 해지
  onClickCardUnregister(){
    const requestCallBack = () => {
      this.deleteCardUnregister();
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `카드를 정말 해지하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '카드 해지하기',
      confirmCallBack: requestCallBack,
    });
  }
  // 카드 정지
  onClickCardSuspend(){
    const requestCallBack = () => {
      this.postCardSuspend();
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `카드를 정말 정지하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '카드 정지하기',
      confirmCallBack: requestCallBack,
    });
  }
  // 카드 정지 해제
  onClickCardSuspendCancel(){
    const requestCallBack = () => {
      this.postCardSuspendCancel();
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `카드 정지를 정말 해제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '카드 정지 헤제하기',
      confirmCallBack: requestCallBack,
    });
  }
  // 무료 재발급 상태로 변경
  onClickCardUpdateFreeCancel(){
    if(!this.cardUpdateFreeCancelReason){
      store.dispatch('commonToast/fetchToastStart', '무료 재발급 사유를 선택해주세요');
      return
    }
    const requestCallBack = () => {
      this.postCardUpdateFreeCancel();
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `해지된 카드를 정말 무료 재발급 가능한 상태로 변경하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '무료 재발급으로 변경',
      confirmCallBack: requestCallBack,
    });
  }
 // 취소로 변경
  onClickCardDiscardCancel(){
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `KB에서 반송 배치로 온 폐기된 카드와 신청서를 취소로 변경하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '취소로 변경',
      confirmCallBack: () => {
        this.postCardDiscardCancel();
      },
    });
  }

  // 카드 신청 내역
  onClickCardApplications(){
    if(this.boardData.CARD_APPLICATIONS.searched) return;
    this.getCardApplications()
  }
  onSearchCardApplications(){
    this.getCardApplications();
  }

  // 카드 배송 정보
  onClickDeliveryData(){
    if(this.boardData.DELIVERY_STATUS.searched) return;
    this.getDeliveryRefresh()
  }
  onSearchDeliveryData(){
    this.getDeliveryRefresh();
  }

  // 카드 사용 내역
  onClickCardHistory(){
    if(this.boardData.CARD_HISTORY.searched) return;
    this.getCardHistoryData();
  }
  onSearchCardHistory(){
    this.getCardHistoryData();
  }
  onClickCardTransactionCertificate(){
    this.postCardTransactionCertificate()
  }

  // 은행(쿠콘) 입출금 내역
  onClickTransData(){
    if(this.boardData.TRANS_HISTORY.searched) return;
    this.getTransData();
  }
  onSearchTransData(){
    this.getTransData();
  }

  // 가상계좌 거래내역
  onClickVirtualAccountTransData(){
    if(this.boardData.VIRTUAL_ACCOUNT_TRANSACTIONS.searched) return;
    this.getVirtualAccountTransData();
  }
  onSearchVirtualAccountTransData(){
    this.getVirtualAccountTransData();
  }

  // 바코드 결제내역
  onClickBarcodeTransationHistory(){
    if(this.boardData.BARCODE_TRANSACTION_HISTORY.searched) return;
    this.getBarcodeTransationHistory();
  }
  onSearchBarcodeTransationHistory(){
    this.getBarcodeTransationHistory();
  }

  // 지갑 내역
  onClickWalletHistoryData(){
    if(this.boardData.WALLET_HISTORY.searched) return;
    this.getWalletHistoryData();
  }
  onSearchWalletHistory(){
    this.getWalletHistoryData();
  }

  // 퍼핀 머니 내역
  onClickMoneyHistoryData(){
    if(this.boardData.MONEY_HISTORY.searched) return;
    this.getMoneyHistoryData();
  }
  onSearchMoneyHistory(){
    this.getMoneyHistoryData();
  }

  // 포인트 내역
  onClickPointHistoryData(){
    if(this.boardData.POINT_HISTORY.searched) return;
    this.getPointHistoryData();
  }
  onSearchPointHistory(){
    this.getPointHistoryData();
  }

  // 송금/결제 한도
  onClickLimitFold() {
    if(this.boardData.TRANSACTION_LIMIT.searched) return;
    this.getTransactionLimitData();
    this.getTransactionLimitPossibleData();
  }
  onSearchTransactionLimit(){
    this.getTransactionLimitData();
  }
  onSearchTransactionLimitPossible(){
    this.getTransactionLimitPossibleData();
  }

  // 소득 공제
  onClickDeductionFold() {
    if(this.boardData.DEDUCTION_HISTORY.searched) return;
    this.getDeductionHistoryData();
  }
  onSearchDeductionHistory(){
    this.getDeductionHistoryData();
  }

  // 멤버십 구매내역
  onClickMembershipFold(){
    this.boardData.MEMBERSHIP_PAYMENT_HISTORY.paginationData.totalCount = this.detailData.memberships.length;
  }
  onClickMembershipCancel(data){
    if(this.boardData.MEMBERSHIP_PAYMENT_HISTORY.isShowRowCheck){
      this.resetCheckCopy();
    }
    this.boardData.MEMBERSHIP_PAYMENT_HISTORY.selectedDataList = [];
    this.boardData.MEMBERSHIP_PAYMENT_HISTORY.selectedDataList.push(data);
    this.boardData.MEMBERSHIP_PAYMENT_HISTORY.isMembershipPaymentCancelView = true;
  }
  onClickMembershipCancelCompelete(){
    this.boardData.MEMBERSHIP_PAYMENT_HISTORY.cancelModel.setId(this.boardData.MEMBERSHIP_PAYMENT_HISTORY.selectedDataList[0].user_membership_id);
    this.postMembershipPaymentCancel();
  }
  // 테이블 복사
  onClickShowRowCheck(){
    this.boardData.MEMBERSHIP_PAYMENT_HISTORY.isMembershipPaymentCancelView = false;
    this.boardData.MEMBERSHIP_PAYMENT_HISTORY.selectedDataList = [];
    this.boardData.MEMBERSHIP_PAYMENT_HISTORY.isShowRowCheck = true;
    this.boardData.MEMBERSHIP_PAYMENT_HISTORY.boardData.drawDataList.unshift(this.boardData.MEMBERSHIP_PAYMENT_HISTORY.checkboxData);
  }
  onClickCopyTable(){
    var myCopyArea = document
      .getElementById('membershipPaymentBoard')
      .getElementsByTagName('tbody')[0];
    var rows = myCopyArea.getElementsByTagName("tr");
    let copyDataList = [];
    Array.from(rows).forEach(rowItem => {
      var rowKeyName = this.boardData.MEMBERSHIP_PAYMENT_HISTORY.boardData.option.rowIdValue || 'id';
      var isSelectRow = this.boardData.MEMBERSHIP_PAYMENT_HISTORY.selectedDataList.find(selectRowItem => rowItem.outerText.indexOf(selectRowItem[rowKeyName]) >= 0);
      if(isSelectRow){
        var rowText = rowItem.outerText.slice(1).replace('\t구매취소','');
        copyDataList.push(rowText);
      }
    });
    const copyData = copyDataList.join('\n');
    window.navigator.clipboard.writeText(copyData).then(() => {
      store.dispatch('commonToast/fetchToastStart', '테이블데이터가 복사되었습니다');
      this.resetCheckCopy();
    });
  }
  resetCheckCopy(){
    if(this.boardData.MEMBERSHIP_PAYMENT_HISTORY.isShowRowCheck){
      this.boardData.MEMBERSHIP_PAYMENT_HISTORY.boardData.drawDataList.shift();
    }
    this.boardData.MEMBERSHIP_PAYMENT_HISTORY.checkboxOnlyOne = false;
    this.boardData.MEMBERSHIP_PAYMENT_HISTORY.isShowRowCheck = false;
    this.boardData.MEMBERSHIP_PAYMENT_HISTORY.selectedDataList = [];
  }
  // 앱 사용 차단
  onClickBlockAppUse(){
    this.postBlockAppUse();
  }
  // 가입 차단
  onClickBlockRegister(){
    this.postBlockRegister();
  }
  // 차단 내역
  onClickBlockFold(){
    this.getBlockSearch();
  }
  // 차단 내역 삭제
  onClickBlockDelete(uid,type){
    this.postBlockDelete(uid,type);
  }
  // 가상계좌 차단
  onClickVirtualAccountBlock(){
    this.postVirtualAccountBlock();
  }
  // 가상계좌 차단 해제
  onClickVirtualAccountUnBlock(){
    this.postVirtualAccountUnBlock();
  }


  // [API] 카드 디자인 변경
  postCardChangeDesign(){
    const path = `${apiPath.CS_CARD_DESIGN_CHAGNE}`;
    const body =  {
      "application_uid": this.detailData.cardApplication.uid,
      "firfin_user_uid": this.detailData.firfinUserUid,
      "changed_card_design_type": this.cardDesignChangedType
    }
    POST_REQUEST(path,body).then(
    (success) => {
      // const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '카드 디자인이 변경되었습니다');
      this.onClickCardDesignChangeClose();
      this.onSearchList();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 카드 신청 내역
  getCardApplications(){
    this.boardData.CARD_APPLICATIONS.searched = false;
    this.boardData.CARD_APPLICATIONS.dataList = [];
    this.boardData.CARD_APPLICATIONS.responseDate = '';
    const query = makeQueryStringByObject(this.boardData.CARD_APPLICATIONS.searchParams);
    const path = `${apiPath.CS_CARD_APPLICATIONS.format(this.detailData.firfinUserUid)}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      this.boardData.CARD_APPLICATIONS.searched = true;
      let resultData = success.data.body;
      resultData.total = resultData.applications.length;
      resultData.totalPage = 1;
      this.boardData.CARD_APPLICATIONS.dataList = resultData.applications;
      this.boardData.CARD_APPLICATIONS.paginationData.totalCount = resultData.total;
      this.boardData.CARD_APPLICATIONS.paginationData.totalPage = resultData.totalPage;
      this.boardData.CARD_APPLICATIONS.hasData = resultData.total > 0;
      this.boardData.CARD_APPLICATIONS.responseDate = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 배달 상태 조회 요청
  getDeliveryRefresh(){
    const path = `${apiPath.CS_CARD_DELIVERY_REFRESH.format(this.detailData.cardApplication.uid,this.detailData.firfinUserUid)}`;
    GET_REQUEST(path).then(
    (success) => {
      this.getDeliveryData();
    }, (failed) => {
      this.boardData.DELIVERY_STATUS.dataList = [];
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    });
  }
  // [API] 배달 상태 정보보기
  getDeliveryData(){
    this.boardData.DELIVERY_STATUS.searched = false;
    this.boardData.DELIVERY_STATUS.dataList = [];
    this.boardData.DELIVERY_STATUS.responseDate = '';
    const path = `${apiPath.CS_CARD_DELIVERY.format(this.detailData.firfinUserUid)}`;
    GET_REQUEST(path).then(
    (success) => {
      this.boardData.DELIVERY_STATUS.searched = true;
      const resultData = success.data;
      this.boardData.DELIVERY_STATUS.dataList = resultData;
      this.boardData.DELIVERY_STATUS.hasData = resultData && resultData.length > 0;
      this.boardData.DELIVERY_STATUS.responseDate = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
    }, (failed) => {
      this.boardData.DELIVERY_STATUS.dataList = [];
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    });
  }
  // [API] 카드 사용 내역
  getCardHistoryData(){
    this.boardData.CARD_HISTORY.searched = false;
    this.boardData.CARD_HISTORY.dataList = [];
    this.boardData.CARD_HISTORY.responseDate = '';
    const query = makeQueryStringByObject(this.boardData.CARD_HISTORY.searchParams);
    const path = `${apiPath.CS_CARD_HISTORY.format(this.detailData.firfinUserUid)}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      this.boardData.CARD_HISTORY.searched = true;
      const resultData = success.data;
      this.boardData.CARD_HISTORY.dataList = resultData.data;
      this.boardData.CARD_HISTORY.paginationData.totalCount = resultData.total;
      this.boardData.CARD_HISTORY.paginationData.totalPage = resultData.totalPage;
      this.boardData.CARD_HISTORY.hasData = resultData.total > 0;
      this.boardData.CARD_HISTORY.responseDate = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 카드 사용 내역 증명서 발급
  postCardTransactionCertificate(){
    const path = `${apiPath.CS_CARD_TRANSACTION_CERTIFICATE}`;
    const body = {
      body: {
        "firfin_user_uid": this.detailData.firfinUserUid,
        "start_at": `${this.cardTransactionCertificate.start_date} 00:00:00`,
        "end_at": `${this.cardTransactionCertificate.end_date} 00:00:00`,
      },
      header:{
        request_at: new Date()
      }
    }
    POST_REQUEST(path,body).then(
    (success) => {
      store.dispatch('commonToast/fetchToastStart', `${this.cardTransactionCertificate.start_date} ~ ${this.cardTransactionCertificate.end_date}의 카드 사용 내역 증명서가 발급되었습니다.`);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 은행(쿠콘) 입출금 내역 보기
  getTransData(){
    this.boardData.TRANS_HISTORY.searched = false;
    this.boardData.TRANS_HISTORY.dataList = [];
    this.boardData.TRANS_HISTORY.responseDate = '';
    const query = makeQueryStringByObject(this.boardData.TRANS_HISTORY.searchParams);
    const path = `${apiPath.CS_TRANS_HISTORY.format(this.detailData.payUserId)}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      this.boardData.TRANS_HISTORY.searched = true;
      const resultData = success.data;
      this.boardData.TRANS_HISTORY.dataList = resultData.data;
      this.boardData.TRANS_HISTORY.paginationData.totalCount = resultData.total;
      this.boardData.TRANS_HISTORY.paginationData.totalPage = resultData.totalPage;
      this.boardData.TRANS_HISTORY.hasData = resultData.total > 0;
      this.boardData.TRANS_HISTORY.responseDate = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }


  // [API] 가상계좌 차단
  postVirtualAccountBlock(){
    const path = `${apiPath.CS_VIRTUAL_ACCOUNT_BLOCK.format(this.detailData.firfinUserUid)}`;
    POST_REQUEST(path).then(
    (success) => {
      // const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '가상계좌가 차단되었습니다');
      this.onSearchList();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 가상계좌 차단 해제
  postVirtualAccountUnBlock(){
    const path = `${apiPath.CS_VIRTUAL_ACCOUNT_UNBLOCK.format(this.detailData.firfinUserUid)}`;
    POST_REQUEST(path).then(
    (success) => {
      // const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '가상계좌 차단이 해제되었습니다');
      this.onSearchList();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 가상계좌 거래내역
  getVirtualAccountTransData(){
    this.boardData.VIRTUAL_ACCOUNT_TRANSACTIONS.searched = false;
    this.boardData.VIRTUAL_ACCOUNT_TRANSACTIONS.dataList = [];
    this.boardData.VIRTUAL_ACCOUNT_TRANSACTIONS.responseDate = '';
    const query = makeQueryStringByObject(this.boardData.VIRTUAL_ACCOUNT_TRANSACTIONS.searchParams);
    const path = `${apiPath.CS_VIRTUAL_ACCOUNT_TRANSACTIONS.format(this.detailData.firfinUserUid)}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      this.boardData.VIRTUAL_ACCOUNT_TRANSACTIONS.searched = true;
      const resultData = success.data;
      this.boardData.VIRTUAL_ACCOUNT_TRANSACTIONS.dataList = resultData.data;
      this.boardData.VIRTUAL_ACCOUNT_TRANSACTIONS.paginationData.totalCount = resultData.total;
      this.boardData.VIRTUAL_ACCOUNT_TRANSACTIONS.paginationData.totalPage = resultData.totalPage;
      this.boardData.VIRTUAL_ACCOUNT_TRANSACTIONS.hasData = resultData.total > 0;
      this.boardData.VIRTUAL_ACCOUNT_TRANSACTIONS.responseDate = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  
  // [API] 바코드 결제내역
  getBarcodeTransationHistory(){
    this.boardData.BARCODE_TRANSACTION_HISTORY.searched = false;
    this.boardData.BARCODE_TRANSACTION_HISTORY.dataList = [];
    this.boardData.BARCODE_TRANSACTION_HISTORY.responseDate = '';
    const query = makeQueryStringByObject(this.boardData.BARCODE_TRANSACTION_HISTORY.searchParams);
    const path = `${apiPath.CS_BARCODE_TRANSACTION_HISTORY.format(this.detailData.firfinUserUid)}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      this.boardData.BARCODE_TRANSACTION_HISTORY.searched = true;
      const resultData = success.data.body;
      console.log(resultData)
      this.boardData.BARCODE_TRANSACTION_HISTORY.dataList = resultData.barcodeTransactionHistoryList;
      this.boardData.BARCODE_TRANSACTION_HISTORY.paginationData.totalCount = resultData.total;
      this.boardData.BARCODE_TRANSACTION_HISTORY.paginationData.totalPage = resultData.totalPage;
      this.boardData.BARCODE_TRANSACTION_HISTORY.hasData = resultData.total > 0;
      this.boardData.BARCODE_TRANSACTION_HISTORY.responseDate = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  // [API] 지갑 내역 보기
  getWalletHistoryData(){
    this.boardData.WALLET_HISTORY.searched = false;
    this.boardData.WALLET_HISTORY.dataList = [];
    this.boardData.WALLET_HISTORY.responseDate = '';
    const query = makeQueryStringByObject(this.boardData.WALLET_HISTORY.searchParams);
    const path = `${apiPath.CS_WALLET_HISTORY.format(this.detailData.payUserId)}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      this.boardData.WALLET_HISTORY.searched = true;
      const resultData = success.data;
      this.boardData.WALLET_HISTORY.dataList = resultData.data;
      this.boardData.WALLET_HISTORY.paginationData.totalCount = resultData.total;
      this.boardData.WALLET_HISTORY.paginationData.totalPage = resultData.totalPage;
      this.boardData.WALLET_HISTORY.hasData = resultData.total > 0;
      this.boardData.WALLET_HISTORY.responseDate = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 퍼핀머니 내역 보기
  getMoneyHistoryData(){
    this.boardData.MONEY_HISTORY.searched = false;
    this.boardData.MONEY_HISTORY.dataList = [];
    this.boardData.MONEY_HISTORY.responseDate = '';
    const query = makeQueryStringByObject(this.boardData.MONEY_HISTORY.searchParams);
    const path = `${apiPath.CS_MONEY_HISTORY.format(this.detailData.payUserId)}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      this.boardData.MONEY_HISTORY.searched = true;
      const resultData = success.data;
      this.boardData.MONEY_HISTORY.dataList = resultData.data;
      this.boardData.MONEY_HISTORY.paginationData.totalCount = resultData.total;
      this.boardData.MONEY_HISTORY.paginationData.totalPage = resultData.totalPage;
      this.boardData.MONEY_HISTORY.hasData = resultData.total > 0;
      this.boardData.MONEY_HISTORY.responseDate = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 포인트 내역 보기
  getPointHistoryData(){
    this.boardData.POINT_HISTORY.searched = false;
    this.boardData.POINT_HISTORY.dataList = [];
    this.boardData.POINT_HISTORY.responseDate = '';
    const query = makeQueryStringByObject(this.boardData.POINT_HISTORY.searchParams);
    const path = `${apiPath.CS_POINT_HISTORY.format(this.detailData.payUserId)}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      this.boardData.POINT_HISTORY.searched = true;
      const resultData = success.data;
      this.boardData.POINT_HISTORY.dataList = resultData.data;
      this.boardData.POINT_HISTORY.paginationData.totalCount = resultData.total;
      this.boardData.POINT_HISTORY.paginationData.totalPage = resultData.totalPage;
      this.boardData.POINT_HISTORY.hasData = resultData.total > 0;
      this.boardData.POINT_HISTORY.responseDate = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 송금/결제 한도 조회
  getTransactionLimitData(){
    this.boardData.TRANSACTION_LIMIT.searched = false;
    this.boardData.TRANSACTION_LIMIT.data = {};
    this.boardData.TRANSACTION_LIMIT.responseDate = '';
    const path = `${apiPath.CS_TRANSACTION_LIMIT.format(this.detailData.firfinUserUid)}`;
    GET_REQUEST(path).then(
    (success) => {
      this.boardData.TRANSACTION_LIMIT.searched = true;
      const resultData = success.data;
      this.boardData.TRANSACTION_LIMIT.data = resultData.body;
      this.boardData.TRANSACTION_LIMIT.hasData = true;
      this.boardData.TRANSACTION_LIMIT.responseDate = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 송금/결제 한도 사용 가능 금액 조회
  getTransactionLimitPossibleData(){
    this.boardData.TRANSACTION_LIMIT_POSSIBLE.searched = false;
    this.boardData.TRANSACTION_LIMIT_POSSIBLE.data = [];
    this.boardData.TRANSACTION_LIMIT_POSSIBLE.responseDate = '';
    const path = `${apiPath.CS_TRANSACTION_LIMIT_POSSIBLE.format(this.detailData.firfinUserUid)}`;
    GET_REQUEST(path).then(
    (success) => {
      this.boardData.TRANSACTION_LIMIT_POSSIBLE.searched = true;
      const resultData = success.data;
      this.boardData.TRANSACTION_LIMIT_POSSIBLE.data = resultData.body;
      this.boardData.TRANSACTION_LIMIT_POSSIBLE.hasData = true;
      this.boardData.TRANSACTION_LIMIT_POSSIBLE.responseDate = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 소득 공제 내역 보기
  getDeductionHistoryData(){
    this.boardData.DEDUCTION_HISTORY.searched = false;
    this.boardData.DEDUCTION_HISTORY.dataList = [];
    this.boardData.DEDUCTION_HISTORY.responseDate = '';
    const query = makeQueryStringByObject(this.boardData.DEDUCTION_HISTORY.searchParams);
    const path = `${apiPath.CS_TAX_HISTORY.format(this.detailData.firfinUserUid)}${query}`;
    GET_REQUEST(path).then(
    (successResponse) => {
      this.boardData.DEDUCTION_HISTORY.searched = true;
      const resultData = successResponse.data;

      let list = [];

      resultData.body.forEach(item => {
        item.tax_return_cards.map(card => {
          list.push({
            id: item.tax_return_application.id,
            parents_name: item.tax_return_application.parents_name,
            child_name: item.tax_return_application.child_name,
            status: item.tax_return_application.status,
            created_at: item.tax_return_application.created_at,
            etc_back_hash: item.etc_back.etc_back_hash,
            card_id: card.card_id,
            card_number: card.card_number,
            card_status: card.card_status,
            card_created_at: card.card_created_at,
          })
        })
      });

      this.boardData.DEDUCTION_HISTORY.dataList = list;

      this.boardData.DEDUCTION_HISTORY.paginationData.totalCount = resultData.total;
      this.boardData.DEDUCTION_HISTORY.paginationData.totalPage = resultData.totalPage;
      this.boardData.DEDUCTION_HISTORY.hasData = resultData.total > 0;
      this.boardData.DEDUCTION_HISTORY.responseDate = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 저금통 강제해지
  deleteDiscardSavings(){
    const path = `${apiPath.CS_DISCARD_SAVINGS.format(this.detailData.firfinUserUid, this.detailData.savings.uid)}`;
    DELETE_REQUEST(path).then(
    (success) => {
      // const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '저금통이 강제해지되었습니다.');
      this.onSearchList();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 리더에게 머니 반환
  postRefundToLeader(refundMoneyToPost){
    const path = `${apiPath.CS_REFUND_TO_LEADER}`;
    const body = {
      "sender": this.detailData.firfinUserUid,
      "amount": refundMoneyToPost
    }
    POST_REQUEST(path,body).then(
    (success) => {
      // const resultData = success.data;
    store.dispatch('commonToast/fetchToastStart', '리더에게 머니가 반환되었습니다.');
      this.onSearchList();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 카드취소
  getCardCancel(){
    const path = `${apiPath.CS_CARD_CANCEL.format(this.detailData.cardApplication.uid, this.detailData.firfinUserUid)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '카드신청이 취소되었습니다.');
      this.onSearchList();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 카드해지
  deleteCardUnregister(){
    const path = `${apiPath.CS_CARD_UNREGISTER.format(this.detailData.firfinUserUid)}`;
    DELETE_REQUEST(path).then(
    (success) => {
      // const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '카드가 해지되었습니다.');
      this.onSearchList();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 카드정지
  postCardSuspend(){
    const path = `${apiPath.CS_CARD_SUSPEND}`;
    const body = {
      "card_number": this.detailData.card.cardNumber,
      "firfin_user_uid": this.detailData.firfinUserUid,
      "suspend_type": this.isCardSuspendReason,
    }
    POST_REQUEST(path,body).then(
    (success) => {
      // const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '카드가 정지 상태로 변경되었습니다.');
      this.onSearchList();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 카드정제해제
  postCardSuspendCancel(){
    const path = `${apiPath.CS_CARD_SUSPEND_CANCEL}`;
    const body = {
      "card_number": this.detailData.card.cardNumber,
      "firfin_user_uid": this.detailData.firfinUserUid,
    }
    POST_REQUEST(path,body).then(
    (success) => {
      // const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '카드정지가 해제되었습니다.');
      this.onSearchList();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 해지된 카드 무료발급상태로 변경
  postCardUpdateFreeCancel(){
    const path = `${apiPath.CS_CARD_UPDATE_FREE_CANCEL}`;
    const body = {
      "application_uid": this.detailData.cardApplication.uid,
      "firfin_user_uid": this.detailData.firfinUserUid,
      "reason": this.cardUpdateFreeCancelReason,
      "description": this.cardUpdateFreeCancelEtcReason,
    }
    POST_REQUEST(path,body).then(
    (success) => {
      // const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '카드가 무료 재발급 상태로 변경되었습니다.');
      this.onSearchList();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] KB에서 반송 배치로 온 폐기된 카드와 신청서를 취소로 변경
  postCardDiscardCancel(){
    const path = `${apiPath.CS_CARD_DISCARD_CANCEL}`;
    const body = {
      "application_uid": this.detailData.cardApplication.uid,
      "firfin_user_uid": this.detailData.firfinUserUid
    }
    POST_REQUEST(path,body).then(
    (success) => {
      // const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '카드 취소로 변경되었습니다.');
      this.onSearchList();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  // [API] 멤버십 구매 취소
  postMembershipPaymentCancel(){
    const path = `${apiPath.MEMBERSHIP_PAYMENTS_CANCEL}`;
    const data = this.boardData.MEMBERSHIP_PAYMENT_HISTORY.cancelModel.getData();
    POST_REQUEST(path, data).then(
    (success) => {
      const resultData = success.data;
      this.isMembershipPaymentDetailPopup = false;
      this.isMembershipPaymentCancelPopup = false;
      this.detailData = {};
      store.dispatch('commonToast/fetchToastStart', '멤버십 구매가 취소되었습니다');
      this.onSearchList();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  // [API] 앱 사용 차단
  postBlockAppUse(){
    const path = `${apiPath.CS_BLOCK_APP_USE.format(this.detailData.firfinUserUid)}`;
    const body =  {
      body:{
        "reason": this.blockData.NOT_ALLOW_USE_APP.reason,
      },
      header:{
        request_at: new Date()
      }
    }
    POST_REQUEST(path,body).then(
    (success) => {
      // const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', `${this.detailData.name}계정의 엡 사용이 차단되었습니다`);
      this.blockData.NOT_ALLOW_USE_APP.reason = '';
      this.getBlockSearch();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 가입 차단
  postBlockRegister(){
    const path = `${apiPath.CS_BLOCK_REGISTER.format(this.detailData.firfinUserUid)}`;
    const body =  {
      body:{
        "reason": this.blockData.USER_JOIN_BLOCK.reason,
      },
      header:{
        request_at: new Date()
      }
    }
    POST_REQUEST(path,body).then(
    (success) => {
      // const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', `${this.detailData.name}계정의 가입이 차단되었습니다`);
      this.blockData.USER_JOIN_BLOCK.reason = '';
      this.getBlockSearch();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 차단 내역
  getBlockSearch(){
    const searchParams = {firfinUserUid : this.detailData.firfinUserUid};
    const query = makeQueryStringByObject(searchParams);
    const path = `${apiPath.CS_BLOCK_HISTORY}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      // this.boardData.BLOCK_HISTORY.searched = true;
      let resultData = success.data.body;
      resultData.total = resultData.length;
      resultData.totalPage = 1;

      const notAllowUseAppData = resultData.find(item => item.blacklistType === 'NOT_ALLOW_USE_APP');
      this.blockData.NOT_ALLOW_USE_APP.data = notAllowUseAppData;
      this.blockData.NOT_ALLOW_USE_APP.status = notAllowUseAppData && notAllowUseAppData.blocked ? 'BLOCK' : 'UNBLOCK';

      const userJoinBlockData = resultData.find(item => item.blacklistType === 'USER_JOIN_BLOCK');
      this.blockData.USER_JOIN_BLOCK.data = userJoinBlockData;
      this.blockData.USER_JOIN_BLOCK.status = userJoinBlockData && userJoinBlockData.blocked ? 'BLOCK' : 'UNBLOCK';
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 차단 내역 해제
  postBlockDelete(uid,type){
    const path = `${apiPath.CS_BLOCK_DELETE.format(uid)}`;
    const body =  {
      body:{
        "reason": this.blockData[type].reason,
      },
      header:{
        request_at: new Date()
      }
    }
    POST_REQUEST(path,body).then(
    (success) => {
      // const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', `해당 차단내용이 해제되었습니다`);
      this.blockData[type].reason = '';
      this.onClickBlockFold();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

}